import { info } from 'console';
import React, { useEffect, useRef, useState } from 'react';
import { useScreenDetector } from '../hooks/useScreenDetector';
import { Formik } from 'formik';

interface FormProps {
  focus?: boolean,
  textComponent?: string,
  data?: any,
  onDataChange?: (data: any) => void,
  padding?: number,
  error?: string | null,
  disable?: boolean
}

export const Form = ({
  focus,
  textComponent = 'p',
  data = {},
  onDataChange,
  padding = 30,
  disable,
  error
}: FormProps) => {

  const { isMobile } = useScreenDetector()

  const [response, setResponse] = useState<any>(
    Object.entries(data).reduce(
      (prev: any, curr: any) => {
        prev[curr[0]] = ''
        return prev
      },
      {}
    )
  )

  const updateInfoData = (field: string, value: string) => {
    response[field] = value
    const newObj = JSON.parse(JSON.stringify(response))
    if (onDataChange) {
      onDataChange(newObj)
    }

    setResponse(newObj)
  }

  const Label = ({text, field}: any) => {
    return React.createElement(
      textComponent,
      {
        css: {
        },
        style: {
          margin: 0,
          marginRight: '20px',
          textWrap: 'nowrap',
        },
        suppressContentEditableWarning: true,
      },
      <strong>{text}:</strong>
    )
  }

  const Input = ({text, field, props, value}: any) => {
    return React.createElement(
      'input',
      {
        css: { 
          textAlign: 'center',
        },
        style: {
          //width: 'calc(100% - 20px)',
          margin: 0,
          backgroundColor : 'black',
          padding: '10px',
          color: "white",
          overflow: 'auto'
        },
        type: 'text',
        name: field,
        disable,
        value: value,
        onChange: (e: any) => {
          updateInfoData(field, e.target.value)
          e.target.focus()
        },
        key:field,
        ...props
      },
    )
  }

  const Select = ({text, field, props, value}: any) => {
    const tempOptions = ['Patient', 'Provider']
    const options = tempOptions.map((option) => React.createElement(
      'option', {
        style: {
          color: 'white'
        },
        value: option
      },
      option
    ))
    return React.createElement(
      'select',
      {
        css: { 
          textAlign: 'center',
        },
        style: {
          width: 'calc(100% - 20px)',
          height: '40px',
          margin: 0,
          backgroundColor : 'black',
          padding: '10px',
          color: "white",
          borderRadius: 0
        },
        type: 'text',
        name: field,
        disable,
        value: value,
        onChange: (e: any) => {
          updateInfoData(field, e.target.value)
          e.target.focus()
        },
        key:field,
        ...props
      },
      options
    )
  }

  return (
    <Formik
      enableReinitialize
      initialValues={response}
      onSubmit={() => {}}
    >
      <form css={{width: '100%'}}>
        <div
          css={{
            padding: padding + "px",
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: '20px'
          }}
        >
          {Object.entries(data).map((obj: any, index) => {
            const field = obj[0]
            const type = obj[1].type
            const value = response[field]
            const isHidden = obj[1].hidden
            return (
              <div
                css={{
                  display: "flex",
                  flexDirection: 'column',
                  gap: '5px',

                }} 
                key={obj[1].field}
              >
                <Label text={obj[1].field} />
                {type === 'input' &&  Input({value:value, text:obj[1].field,field:obj[0], props:obj[1].props})}
                {type === 'select' &&  Select({value:value, text:obj[1].field,field:obj[0], props:obj[1].props})}
              </div>
            )
          })}
          {error && (
            <p css={{ color: '#b34f4f'}}>{error}</p>
          )}
        </div>
      </form>
    </Formik>
  )

}