import React, { useEffect, useRef, useState } from 'react';

interface SearchProps {
}

export const Search = ({
}: SearchProps) => {
    const [ searchActive, setSearchActive ] = useState(false)
    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (inputRef) {
            inputRef.current?.focus()
        }

    }, [searchActive])
  useEffect(() => {

    const onKeyDown = (e: any) => {
      if (e.ctrlKey && e.key === 'f') {
        // Prevent the Save dialog to open
        e.preventDefault();
        // Place your code here
        setSearchActive(search => {
        const  newValue = !search
        return newValue 
        })
      }
    }
    return document.addEventListener('keydown', e => {
        onKeyDown(e)
    });
  }, [])
  return searchActive ? (
    <div
        css={{
            position: 'absolute',
            width: '100vw',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            zIndex: 1,
            backdropFilter: 'blur(6px)'
        }}
    >
        <input
            ref={inputRef}
            placeholder='Find'
            css={{
                marginTop: "200px",
                width: "600px",
                height: "50px",
                borderRadius: '10px',
                backgroundColor: '#d8d2c7',
                padding: '20px',
                fontSize: '1.5rem'
            }}
            type="search"
        >
        </input>
    </div>
  ): null

}