import React, { useEffect, useRef } from 'react';
import { useScreenDetector } from '../hooks/useScreenDetector';

interface ButtonProps {
  name: string,
  onClick?: () => void,
  secondButtonName?: string
  secondButtonOnClick?: () => void,
  isLoading?: boolean
}

export const Button = ({
  name,
  onClick,
  secondButtonOnClick,
  secondButtonName,
  isLoading = false
}: ButtonProps) => {
  const buttonRef = useRef<HTMLDivElement>(null)
  const { isMobile } = useScreenDetector()

  useEffect(() => {

    if (!buttonRef.current) {
      return 
    }

    buttonRef.current.addEventListener("keydown", (e) => {
    if (e.code === 'Enter') {  //checks whether the pressed key is "Enter"
      if (onClick) {
        onClick()
        //(buttonRef.current as any).style.backgroundColor =  'unset'

      }
    }
});

  }, [buttonRef.current])

  return (
    <div
      css={{
        //maxWidth: isMobile ? '' : '250px'
      }}
    >
    <div
      ref={buttonRef}
      css={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: "99%",
        border: '1px solid white',
        borderRight: "1px solid #767676",
        borderBottom: "1px solid #767676",
        cursor: isLoading ? 'progress':'pointer',
        backgroundImage: 'linear-gradient(to right, rgba(255,0,0,0), rgb(54 110 171 / 18%))',
        overflow: 'hidden',

        ':active': {
          backgroundColor: 'darkblue'
        },
        ':hover': {
          backgroundColor: 'blue',
        overflow: 'hidden',
        },
        'input[type=email]': {
          color: 'white'
        }
      }}
      onClick={onClick}

    ><p  >
      {isLoading ? '. . .' : name}</p>
    </div> 
    {secondButtonName && 
        <div
          css={{
            marginTop: '0px',
            ':hover': {
              color: 'blue'
            },
            textAlign: 'center'

          }}
          onClick={() => {

            secondButtonOnClick && secondButtonOnClick()
          }}
        >
          <p
            css={{...{
              cursor: 'pointer',
            ':hover': {
              color: '#9292b0'
            }
            },...isMobile ? {
              fontSize: "12px"
            } :{
            } 
          }}
          >{secondButtonName}</p>

        </div>
    
    }
    </div>
  )

}