import React, { useEffect, useRef, useState } from 'react';
import useLocalStorage from 'use-local-storage';

import { Upload } from '../components/Upload';
import { DelayedRender } from '../components/DelayedRender';
import { List } from '../components/List';
import { Tabs } from '../components/Tabs';

import { Info } from './Info';
import { useParams } from 'react-router-dom';
import { getRoomsForPatient } from '../matrix/people';
import { PatientMessages } from './PatientMessages';
import { Back } from '../Routes';
import { Form } from '../components/Form';

const BORDER_SIZE = 4;


export const Patient = () => {
  const rightPanelRef = useRef<HTMLDivElement>(null)
  const panelTabRef = useRef<HTMLDivElement>(null)
  const [ hideContent, setHideContent ] = useState(false)
  const [ mPosWidth, setMPosWidh] = useLocalStorage('m_pos_width', "calc(100%/2)")
  const m_pos = useRef<number>(0)
  const { id } = useParams()

  const [ messagesRoomId, setMessagesRoomId] = useState('')
  const [ updateRoomId, setUpdateRoomId] = useState('')

  useEffect(() => {

    if (!id) {
      return
    }

    const rooms = getRoomsForPatient(id)

    const messageRoom = rooms.find(room => room.name.includes("messages-2"))
    const updateRoom = rooms.find(room => room.name.includes("messages-2"))

    if (messageRoom) {
      setMessagesRoomId(messageRoom.roomId)
    }

    if (updateRoom) {
      setUpdateRoomId(updateRoom.roomId)
    }



  }, [])

  useEffect(() => {

    if (!rightPanelRef.current ) {
      return
    }

    const panel = rightPanelRef.current
    panel.style.width = mPosWidth
    panel.style.display = "block"

    function resize(e: any) {
      const dx = m_pos.current - e.x;
      m_pos.current = e.x;
      const newWidth = (parseInt(getComputedStyle(panel, '').width) + dx);

      if (newWidth <= 350) {
        (panel.firstElementChild as any).style.flexDirection = 'column';
        (panel.firstElementChild as any).style.gap = '0';
        (panel.firstElementChild as any).style.borderBottom = '0';
        (panel.lastElementChild as any).style.display = 'block';
        panel.style.marginRight = '0';
        panel.style.width = "350px";
        setMPosWidh("350px")
        setHideContent(true)
        return
      } 
      if (newWidth <=500) {
        setHideContent(true)
      } else {
        setHideContent(false)
      }
      
      (panel.firstElementChild as any).style.flexDirection = 'row';
      (panel.firstElementChild as any).style.borderBottom = '2px white dotted';
      (panel.lastElementChild as any).style.display = 'flex';
      (panel.firstElementChild as any).style.gap = '30px';
      panel.style.marginRight = '80px';
      panel.style.width = newWidth + "px"
      setMPosWidh(newWidth + "px")

    }

    panel.addEventListener("mousedown", function (e) {
      if (e.offsetX < BORDER_SIZE) {
        m_pos.current = e.x;
        document.addEventListener("mousemove", resize, false);
      }
    }, false);


    document.addEventListener("mouseup", function () {
      document.removeEventListener("mousemove", resize, false);
    }, false);

  }, [rightPanelRef.current, panelTabRef.current])

  return (
    <div css={{ overflow: 'hidden', padding: "80px", display: "flex", flexDirection: 'column' }}>
      <div css={{ position: 'absolute', top: '0px', left: '10px'}}>
      </div>
      <div>
        <div css={{ paddingLeft: '20px'}}>
          <h1>{id === 'patient123' ? 'Sunny': id}</h1>
          <p css={{ marginTop : '-18px'}} >Last active 2 days ago</p>
        </div>
        <Tabs
          disable={false}
          options={['Info', 'Diagnosis', 'Treatment Plan', 'Interactions']}
          optionRender={{
            'Info': <div
            css={{
              width: '400px'
            }}
            
            >
              </div>,
            'Diagnosis': <DelayedRender><div css={{ display: 'flex', justifyContent: 'center', width:  "calc(100% - " + mPosWidth + ")", marginTop: '200px'}}><Upload name='Publish a diagnosis'/></div></DelayedRender>,
            'Treatments': <DelayedRender><div css={{ display: 'flex', justifyContent: 'center', width:  "calc(100% - " + mPosWidth + ")", marginTop: '200px'}}><Upload name='Create a treatment plan'/></div></DelayedRender>,
            'Interactions': <DelayedRender><div css={{ display: 'flex', justifyContent: 'center', width:  "calc(100% - " + mPosWidth + ")", marginTop: '200px'}}><Upload name='Start an interaction'/></div></DelayedRender>,
          }}
        />

      </div>
      <div>

      </div>
      <div
        css={{
          display: 'none',
          position: "absolute",
          width: "350px",
          paddingLeft: "4px",
          marginRight: "80px",
          height: "calc(100% - 200px)",
          right: "0",
          backgroundColor: "#131313",
          borderLeft: "2px white dotted",
          ":after": {
            content: '""',
            backgroundColor: "transparent",
            position: "absolute",
            left: "0",
            width: "2px",
            cursor: "ew-resize",
            overflow: 'hidden'
          }
        }}
        ref={rightPanelRef}
      >
        <Tabs
          startingTab={1}
          disable={hideContent}
          options={['Assistant', 'Messages']}
          optionRender={{
          'Messages': <PatientMessages hideContent={hideContent} matrixRoomId={messagesRoomId}/>,
          'Activity': <></>,
          'Manage': <List options={['Archive patient', 'Send referral', 'Block']} />
          }}
        /> 
      </div>
    </div>
  )

}