import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Section } from '../components/Section';
import { List } from '../components/List';
import { getPatients } from '../matrix/people';

export const PatientList = () => {
  const navigate = useNavigate()
  const [ patients, setPatients ] = useState<string[]>([])

  useEffect(() => {

    const patients = async () => {
      const p = await getPatients()
      const patientsParsed = p.map((p) => {

        const split = p.split(":")
        const patient = split[0].substring(1, split[0].length)
        if (patient === 'patient123') {
          return 'Sunny'
        }

        return patient
      })
      setPatients(patientsParsed)
    }

  }, [])

  return (
    <div css={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      paddingLeft: '100px',
      height: "100%",
      width: "calc(100% - 100px)",
      gap: '200px'
    }}>
      <div css={{ width: '900px', overflowY: 'auto', paddingRight: '20px', marginTop: '80px', marginBottom: '80px' }}>
              <Section title="Highlights" leftButtons={[]} rightButtons={['clear']}>
          <div>
            <List
              spacing={4}
              options={[
                '5 new interactions by Sunny',
                '3 new messages from Patient A',
                'Patient C has been reporting that the medicine hasnt been working for them ',
                'Results from Patient A taking Zoloft for 1 month',
                '2 messages from Sunny'
              ]}
              showBorder={false} 
              onClick={(option) => navigate('/patient/'+"patient123")}
            />
          </div>
        </Section>
        <Section title="Patients who have appointments this week" rightButtons={['clear']}>
          <div>
            <List
              spacing={4}
              options={[...patients, 'Patient A', 'Patient B', 'Patient C', 'Patient D', 'Patient E', 'Patient F', 'Patient G', 'Patient H', 'Patient I', 'Patient J', 'Patient K', 'Patient L', 'Patient J', 'Patient K', 'Patient L', 'Patient J', 'Patient K', 'Patient L', 'Patient J', 'Patient K', 'Patient L']}
              showBorder={false} 
              onClick={(option) => navigate('/patient/'+"patient123")}
            />
          </div>
        </Section>


      </div>

    </div>
  )

}