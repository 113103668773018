import { useMutation } from "@tanstack/react-query";

import { alpine_api, getAlpineAPIServer } from "../utils/network";
import { createContext, useEffect, useState } from "react";
import useLocalStorage from "use-local-storage";

export const LOCAL_LOGIN_INFO = 'MATRIX-LOGIN'

export const LOCAL_STORAGE_ACCESS_TOKEN = 'ACCESS-TOKEN'
export const LOCAL_STORAGE_REFRESH_TOKEN = 'REFERSH-TOKEN'
export const LOCAL_STORAGE_USER = 'USER'

export enum AuthState {
  UNKNOWN,
  COMPLETE, 
  CONFIRM_EMAIL
}

export interface LogInRequest {
  email: string,
  password: string,

}
export interface RegisterRequest {
  email: string,
  password: string,
  firstName: string,
  lastName: string,
  role: string,
  pin: number
}

const setAuthorization = (token: string) => {
  alpine_api.interceptors.request.use((config) => {
    config.headers.Authorization = 'Bearer ' + token;
    return config;
  });
}

export const useAuth = () => {

  const [ authState, setAuthState ] = useState(AuthState.UNKNOWN)

  const [ accessToken, setAccessToken ] = useLocalStorage(LOCAL_STORAGE_ACCESS_TOKEN, undefined)
  const [ refreshToken, setRefreshToken ] = useLocalStorage(LOCAL_STORAGE_REFRESH_TOKEN, undefined)
  const [ user, setUser] = useLocalStorage(LOCAL_STORAGE_USER, undefined)

  const [ isLoading, setIsLoading ] = useState(true)

  useEffect(() => {

    const refreshAuth = async () => {
      const data = await fetch(getAlpineAPIServer('/auth/refresh-tokens'), {
        method: 'POST',
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          refreshToken: refreshToken
        })
      })

      if (data.status !== 200) {
        setAuthState(AuthState.UNKNOWN)
        setAccessToken(undefined)
        setRefreshToken(undefined)
        setIsLoading(false)
        return
      } 

      const response = await data.json()
      const newAccessToken = response.access.token
      const newRefreshToken = response.refresh.token

      setAccessToken(newAccessToken)
      setRefreshToken(newRefreshToken)
      setAuthorization(newAccessToken)

      if (user) {
        if (!(user as any).isEmailVerified) {
          setAuthState(AuthState.CONFIRM_EMAIL)
          setIsLoading(false)
          return
        }

        setAuthState(AuthState.COMPLETE)
      }
          
      setIsLoading(false)
    }

    if (refreshToken) {
      refreshAuth()
    } else {
      setAuthState(AuthState.UNKNOWN)
      setIsLoading(false)
    }

  }, [])

  const { data, isError, error, mutateAsync, isPending} = useMutation({
    mutationFn: async (logInRequest: LogInRequest) => {
      const data = await fetch(getAlpineAPIServer('/auth/login'), {
        method: 'POST',
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(logInRequest)
      })

      if (data.status !== 200) {
        setAuthState(AuthState.UNKNOWN)
        return AuthState.UNKNOWN
      } 

      const response = await data.json()
      const accessToken = response.tokens.access.token
      const refreshToken = response.tokens.refresh.token

      console.log("response: ", response)

      setUser(response.user)
      setAccessToken(accessToken)
      setRefreshToken(refreshToken)

      if (!response.user.isEmailVerified) {
        setAuthState(AuthState.CONFIRM_EMAIL)
        return AuthState.CONFIRM_EMAIL
      } else {
        setAuthState(AuthState.COMPLETE)
        setAuthorization(accessToken)
        return  AuthState.COMPLETE
      }
    },
  }); 
  
  const { isError: isRegisterError, error: registerError, mutateAsync: registerMutateAsync, isPending: isRegisterPending} = useMutation({
    mutationKey: ['loginRequest'],
    mutationFn: async (registerRequest: RegisterRequest) => {
      console.log(registerRequest)
      return fetch(getAlpineAPIServer('/auth/register'), {
        method: 'POST',
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(registerRequest)
      })
    },
  });

  const logOut = async () => {
    await alpine_api.post("/auth/logout", {
      refreshToken
    })
    setAccessToken(undefined)
    setRefreshToken(undefined)
    setUser(undefined)
    setAuthState(AuthState.UNKNOWN)
  }

  return {
    state: authState,

    user, 

    logIn: mutateAsync,
    logOut: logOut,
    register: registerMutateAsync,

    isLoading,

    isError,
    isPending,

    error,
  }
}

export const AuthContext = createContext<any>(null)