import React, { useEffect, useRef, useState } from 'react';
import LogoInverse from '../assets/logo_inverse.png'
import { List } from './List';
import { useLogout } from '../matrix/auth';

interface PopupMenuLogoProps {
}

export const Navigation = ({
}: PopupMenuLogoProps) => {
  const [ showMenu, setShowMenu ] = useState(false)
  const logout = useLogout()
  const menuRef = useRef<HTMLDivElement>(null)

  const [ buttonSize, setButtonSize] = useState(30)
  const secondButtonSize = buttonSize === 30 ? 200 : 30

  const onMenuClick = () => {
    setShowMenu(!showMenu)

    console.log("menuRef: ", menuRef)
    if (!menuRef.current) {
      return 
    }



    if (menuRef.current.style.width === '300px' ) {
      menuRef.current.style.width = '90px'
    } else {
      menuRef.current.style.width = '300px'
      } 

  }

  useEffect(() => {



    window.addEventListener('click', function(e){   
    if (!menuRef.current) {
      return
    }
      if (menuRef.current.contains((e as any).target)){
        // Clicked in box
      } else{
        // Clicked outside the box
      }
    });

  }, [menuRef])

  return (
    <div
      css={{
        position: 'absolute',
        top: '80px',
        left: '50px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        transition: 'opacity 800ms ease-out',
        opacity: 0.03,
        gap: '20px',
        '&:hover' :{
        opacity: 1,

        }
      }}
    >

        <div ref={menuRef}
          css={{
            transition: 'width 400ms ease-out;',
            borderTopLeftRadius: '40px',
            borderBottomLeftRadius: '40px',
            width: buttonSize,
            height: '30px',
            backgroundColor: '#dadada',
            borderRadius: '20px',
            cursor: 'pointer'
          }}
          onClick={() => setButtonSize(200)}
        >
          {buttonSize == 220 ? <h1 css={{ color: 'white'}}>Feed</h1> : null}
        </div>
        <div ref={menuRef}
          css={{
            transition: 'width 400ms ease-out;',
            borderTopLeftRadius: '40px',
            borderBottomLeftRadius: '40px',
            width: secondButtonSize,
            height: '30px',
            backgroundColor: '#dadada',
            borderRadius: '20px',
            cursor: 'pointer'
          }}
          onClick={() => setButtonSize(30)}
        >
          {buttonSize === 302 ? <h1 css={{color : 'white'}}>Feed</h1> : null}
        </div>
    </div>
     
      )

}