import React, { useContext, useEffect, useState } from 'react';
import {
  RouterProvider, useNavigate,
} from "react-router-dom";
import { QueryClient} from '@tanstack/react-query';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import './matrix/auth'
import { login } from './matrix/auth';
import { authRouters, patientRoutes, providerRoutes } from './Routes';
import { startMatrix } from './matrix/start';
import 'draft-js/dist/Draft.css';
import { Search } from './components/Search';
import { AuthContext, AuthState, useAuth } from './hooks/useAuth';
import { Logout } from './views/auth/Logout';
import { useScreenDetector } from './hooks/useScreenDetector';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
} as any)

const persister = createSyncStoragePersister({
  storage: window.localStorage,
})

const WithMatrix = () => {
  const [ isMatrixStarted, setIsMatrixStarted ] = useState(false)
  const { user } = useContext(AuthContext) 
  const { isMobile } = useScreenDetector()

  useEffect(() => {
    const start = async () => {
      if (isMatrixStarted){
        return 
      }

      //const loginData = await login(user.matrixUsernameId, user.matrixPassword) 
      const loginData = false 

      if (!loginData) {
        return
      }

      try {
        const started = await startMatrix(loginData)
        setIsMatrixStarted(started)
      } catch (e) {
        console.error("Error!")
      }
    }

    start()
  }, [])

  if (!isMatrixStarted) {
    //if (user.role === 'Patient') {
    if (true) {
      return <RouterProvider router={patientRoutes} />
    } else {
      return <RouterProvider router={providerRoutes} />
    }
  } else {
    return <>
    <Logout /> 
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        fontSize: '50px',
        height: '100%',
        overflowY: isMobile ? 'auto' : 'hidden'
      }}
    >
      ...
    </div></>
  }
}

const WithAuthContext = () => {
  const auth = useAuth()

  if (auth.isLoading) {
    return null
  }

  return (
    <AuthContext.Provider value={auth}>
      <div css={{display:'flex', justifyContent:'center', height: '100vh'}}>
      <Search />
      {auth.state !== AuthState.COMPLETE && <WithMatrix/> }
      {auth.state === AuthState.COMPLETE && <RouterProvider router={authRouters} /> }
      </div>
    </AuthContext.Provider>
  )
}


function App() {
  return (
    <div css={{ overflowY: 'auto'}}>
      <PersistQueryClientProvider
        client={queryClient}
        persistOptions={{ persister}}
      >
        <WithAuthContext />
      </PersistQueryClientProvider>
    </div>
  );
}

export default App;
