import { useQuery } from "@tanstack/react-query";
import { getClient } from "./start";

export const getRooms = () => {
  Object.keys(getClient().store.rooms).forEach((roomId) => {
    console.log("roomid: ", roomId)
});
}

export const testSendToRoom = (message: string) => {
  const content = {
    body: message, 
    msgtype: "m.text",
  };
  getClient().sendEvent(
    "!DHxgCiDTMkkpbSuZmE:matrix.org",
    "m.room.message",
    content,
    "", 
    (err: any, res: any) => {
      console.log(err);
  });
}

export const getRoomsMessages = (roomId: string) => {
  const messages: any[] = []

  let room = getClient().getRoom(roomId);

  if (room) {
      let timeline = room.timeline;
      timeline.forEach((event: any) => {
          console.log("event.gettype: ", event.getType())
          if (event.getType() === 'm.room.message') {
              messages.push({
                sender: event.getSender(),
                message: event.getContent().body})
          }
      });
  }
  return messages
}

export const sendMessageToRoom = (roomId: string, message: string) => {
  const content = {
    body: message, 
    msgtype: "m.text",
  };
  getClient().sendEvent(
    roomId,
    "m.room.message",
    content,
    "", 
    (err: any, res: any) => {
      console.log(err);
  });
}

export const listenToNewRoomMessages = (roomId: string, callback: any) => {
  getClient().on("Room.timeline", (event: any, room: any, toStartOfTimeline: any) => {
    if (event.getType() !== 'm.room.message') {
        return; // Not a message
    }

    if (toStartOfTimeline) {
        return; // Only looking at new messages
    }

    if (room.roomId !== roomId) {
        return; // Not the room we're interested in
    }

    callback({
      sender: event.getSender(),
      message: event.getContent().body
    })
});

}

export const getUnreadMessagesCountForRoom = (roomId: string) => {
  const rooms = getClient().getRooms();
  const room = rooms.find((room: any) => room.roomId === roomId)
  return room.getUnreadNotificationCount('total');
}

export const setRoomAsRead = (roomId: string) => {
  const rooms = getClient().getRooms();
  const room = rooms.find((room: any) => room.roomId === roomId)
  const timeline = room.getLiveTimeline();
  const latestEvent = timeline.getEvents().pop();

  console.log("latestEvents", latestEvent)

  if (latestEvent) {
    console.log("")
    getClient().setRoomReadMarkers(room.roomId, latestEvent.getId(), null).then(() => {
      console.log(`Marked all messages as read in room: ${room.roomId}`);
    }).catch((error: any) => {
      console.error(`Error marking messages as read in room: ${room.roomId}`, error);
    });
  }

}
