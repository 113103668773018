import React, { useEffect, useRef, useState } from 'react';

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Upload } from '../../components/Upload';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { setRoomAsRead, testSendToRoom } from '../../matrix/messages';
import { useNavigate, useParams } from 'react-router-dom';
import { PatientMessages } from '../PatientMessages';
import { useScreenDetector } from '../../hooks/useScreenDetector';



interface NewMessagesProps {
}

export const NewMessages = ({
}: NewMessagesProps) => {
  const { id } = useParams()
  const { isMobile } = useScreenDetector()
  const navigate = useNavigate()

  console.log("id: ", id)

  useEffect(() => {
    if (!id){
        return
    }

    setRoomAsRead(id)
  }, [])

  return (
    <div
      css={{
        display:'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100vw',
        height: '100vh',
        overflow: 'hidden'
      }}
    >
        <h2 css={{
          width: '100%',
          marginLeft: '50px'
    }}
    onClick={() => {
      navigate("/home/true")
    }}
    >Back</h2>
    
        <div css={{width: '100%', height: 'calc(100% - 100px)'}}>
     { id && <PatientMessages matrixRoomId={id} />}

        </div>

    </div>
  )

}