import { useNavigate } from "react-router-dom";
import { createMatrixIdFromName } from "./auth";
import { getClient } from "./start";

export const getPatients = () => {
  let contacts: string[] = [];
  const rooms = getClient().getRooms()
  rooms.forEach((room: any) => {
      if (room.getMyMembership() === 'join') {
          let members = room.getJoinedMembers();
          members.forEach((member: any) => {
              if (member.userId !== getClient().getUserId()) {
                  contacts.push(member.userId);
              }
          });
      }
  });

  const uniqueContacts = [...new Set(contacts)];

  return uniqueContacts
}

export const getRoomsForSelf = (userName: string) => {
  const patientRooms: any[] = []
  const rooms = getClient().getRooms()
  console.log("userName: ", userName)
  rooms.forEach((room: any) => {
    if (room.getMyMembership() === 'join') {
        let members = room.getJoinedMembers();
        console.log("members: ", members)
        console.log("members: ", members)
        const patientInRoom = members.find((member: any) => {
            return member.name === userName
        })

        if (patientInRoom) {
          patientRooms.push({
            roomId: room.roomId,
            name: room.name
          })
        }
        
    }
  });

  return patientRooms 
}

export const getRoomsForPatient = (userName: string) => {
  const patientRooms: any[] = []
  const rooms = getClient().getRooms()
  rooms.forEach((room: any) => {
    if (room.getMyMembership() === 'join') {
        let members = room.getJoinedMembers();
        const patientInRoom = members.find((member: any) => {
          if (member.userId !== getClient().getUserId()) {
            return member.name === userName
          }
        })

        if (patientInRoom) {
          patientRooms.push({
            roomId: room.roomId,
            name: room.name
          })
        }
        
    }
  });

  return patientRooms
}