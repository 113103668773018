import React, { useContext, useEffect, useRef, useState } from 'react';
import LogoInverse from '../assets/logo_inverse.png'
import { List } from './List';
import { useLogout } from '../matrix/auth';
import { AuthContext } from '../hooks/useAuth';

interface PopupMenuLogoProps {
}

export const PopupMenuLogo = ({
}: PopupMenuLogoProps) => {
  const [ showMenu, setShowMenu ] = useState(false)
  const logout = useLogout()
  const menuRef = useRef<HTMLDivElement>(null)
  const { logOut } = useContext(AuthContext)

  const onMenuClick = () => {
    setShowMenu(!showMenu)

    console.log("menuRef: ", menuRef)
    if (!menuRef.current) {
      return 
    }



    if (menuRef.current.style.width === '300px' ) {
      menuRef.current.style.width = '90px'
    } else {
      menuRef.current.style.width = '300px'
      } 

  }

  useEffect(() => {



    window.addEventListener('click', function(e){   
    if (!menuRef.current) {
      return
    }
      if (menuRef.current.contains((e as any).target)){
        // Clicked in box
      } else{
        // Clicked outside the box
      }
    });

  }, [menuRef])

  return (
    <div css={{
        transition: 'opacity 800ms ease-out',
        opacity: 0.03,
        gap: '20px',
        '&:hover' :{
        opacity: 1,

        }
    }}>
        {showMenu && <div css={{
          position: 'absolute',
          width: '100vw',
          height: "100vh",
          top: 0,
          left: 0,
          backgroundColor: '#00000066' 
        }} />}
        <div ref={menuRef}
          css={{
            position: 'absolute',
            top: '0',
            right: '0px',
            width: '90px',
            height: 'calc(100vh - 100px)',
            backgroundColor: '#d8d2c7',
            padding: '0px',
            paddingTop: '100px',
            transition: 'width 400ms ease-out;',
          }}
        >
          {showMenu && (
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%'
              }}
            >
          <List 
            color='black'
            padding={20}
            spacing={10}
            showBorder={false}
            options={['Clinical Team', 'Community', 'Directory', 'Research']}
            onClick={(option) => {
              if (option === 'Log out') {
                logout()
              }
            }}
          />
          
          <List 
            color='black'
            padding={20}
            spacing={10}
            showBorder={false}
            options={[ 'Settings', 'End session']}
            onClick={(option) => {
              if (option === 'End session') {
                logOut()
              }
            }}
          />
          </div>

          )}
        </div>
      <div
        onClick={() => onMenuClick()}
          css={{
            position: 'absolute',
            top: '0',
            right: '0px',
            width: '30px',
            padding: '30px',
          }}
      >
        <img
          src={LogoInverse}
          css={{
            position: "absolute",
            right: 13,
            top: 10,
            cursor: 'pointer'
          }} 
          width="60"
          height="60" 
        />
      </div>
    </div>
  )

}