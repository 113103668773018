import axios from "axios";

const ALPINE_API_SERVER = 'http://localhost:3003/v1'

export const alpine_api = axios.create({
    baseURL:ALPINE_API_SERVER 
  });
  
export const getAlpineAPIServer = (endpoint: string) => {
    return 'http://localhost:3003/v1' + endpoint
}