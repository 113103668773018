import React, { useEffect, useRef } from 'react';

interface SectionProps {
  title: string,
  leftButtons?: string[],
  rightButtons?: string[],
  children?: React.ReactNode
}

export const Section = ({
  title,
  leftButtons,
  rightButtons,
  children
}: SectionProps ) => {
  return (
      <>
        <div css={{ borderBottom: '2px dotted white', width: '100%', position: 'sticky', top: 0, backgroundColor: '#141414'}}>
          <h1 >
            {title}
          </h1>
        </div>
        <div css={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: "-5px"}}>
          <div>
            {leftButtons?.map((buttonTitle, index) => (
              <p css={{  cursor: 'pointer'}} key={index}>{buttonTitle}</p>
            ))}
          </div>
          <div >
            {rightButtons?.map((buttonTitle, index) => (
              <p css={{  cursor: 'pointer'}} key={index}>{buttonTitle}</p>
            ))}
        </div>
      </div>
        {children}
      </>
  )

}