import React, { useEffect, useRef } from 'react';

interface UploadProps {
  name: string,
  onClick?: () => void
}

export const Upload = ({
  name,
  onClick
}: UploadProps) => {
  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: "400px",
        border: '1px solid white',
        cursor: 'pointer',
        backgroundImage: 'linear-gradient(to right, rgba(255,0,0,0), rgb(54 110 171 / 18%))',
        ':hover': {
          backgroundColor: 'blue'
        },
        ':active': {
          backgroundColor: 'darkblue'
        }
      }}
      onClick={onClick}
    >
      <h2 >{name}</h2>
    </div>
  )

}