import React, { useContext, useState } from 'react';
import { useScreenDetector } from '../../hooks/useScreenDetector';
import { useSearchParams } from 'react-router-dom';
import { AuthContext } from '../../hooks/useAuth';

export const Logout = () => {
    const { logOut } = useContext(AuthContext)

  return (
    <div css={{
        position: 'absolute',
        top: 20,
        right: 40,
        cursor: 'pointer'
    }}
    onClick={logOut}
    >
        <p>Logout</p>
    </div>
  )

}