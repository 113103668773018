import React, { useEffect, useState } from 'react';
import { useScreenDetector } from '../../hooks/useScreenDetector';
import { getAlpineAPIServer } from '../../utils/network';
import { useMutation } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import Logo from '../../assets/logo.png'

export const ConfirmEmail = () => {
  const { isMobile } = useScreenDetector()
  const [ isConfirmed, setIsConfirmed ] = useState(false)
  const [ isError, setIsError ] = useState(false)
  const [ searchParamas ] = useSearchParams()

  const { mutate, isPending} = useMutation({
    mutationFn: async () => {
      const token = searchParamas.get("token")

      if (!token) {
        setIsError(true)
        return
      }

      const data = await (await fetch(getAlpineAPIServer(`/auth/verify-email?token=${token}`), {
        method: 'POST',
        headers: {
          "Content-type": "application/json",
        },
      }))

      if (data.status === 204) {
        setIsConfirmed(true)
      } else {
        setIsError(true)
      }
    },
  }); 

  useEffect(() => {
    mutate()
  }, [])

  return (
    <div css={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: "100%",
      width: "100%",
      gap: '50px'
    }}>
      <div
        css={{
          display: 'flex',
          flexDirection: isMobile ? 'row' : 'column',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
          <div
          css={{
            minWidth: isMobile? 'unset' : '400px',
            width: isMobile ? '75%': '50%',
            border: '2px dotted white',
            padding: '50px'
          }}
        >
        
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            gap: '30px'
          }}
        >
          <img
          src={Logo}
          css={{
            alignSelf: 'center'
          }} 
          width="60"
          height="60" 
        />
          <div>
            {isError && !isPending && <h2>Email confirmation failed!</h2>}
            {!isError && isConfirmed && !isPending && <h2>Email successfully confirmed!!</h2>}
          </div>
        
        </div>
        </div>
        </div>
    </div>
  )

}