
import * as sdk from "matrix-js-sdk";
const baseUrl = "http://localhost:8008"

let client: any = undefined
let isClientLoading: boolean = false
let clientStarted: boolean = false

export const startMatrix = async (loginData: any): Promise<boolean> => {
  try {

    if (clientStarted || isClientLoading){
      return true
    }

    isClientLoading = true

    client = sdk.createClient({
      baseUrl,
      accessToken: loginData.access_token,
      userId: loginData.user_id,
    });

    await client.startClient({
      initialSyncLimit: 100
    });

    await new Promise<void>((resolve,reject) => client.once('sync', (state: any, prevState: any, res: any) => {
      if(state === 'PREPARED') {
        resolve()
      }
      })
    )

    clientStarted = true
    isClientLoading = false
    console.log("Successfully started Matrix!")
    return true
  } catch (e) {
    console.log("Error creating client: ", e)
    return false
  }
}

export const getClient = () => {
  return client
}

export { client }

