import React from 'react';
import {
  createBrowserRouter,
  createMemoryRouter,
  useNavigate
} from "react-router-dom";

import { Pin } from './views/auth/Pin';
import { PatientList } from './views/PatientList';
import { Patient } from './views/Patient';
import Logo from './assets/logo.png'

import { Feed } from './views/Feed';
import { Login } from './views/auth/Login';
import { Update } from './views/interactions/Update';
import { PopupMenuLogo } from './components/PopupMenuLogo';
import { NewMessages } from './views/interactions/NewMessages';
import { useIdleTimer } from 'react-idle-timer';
import { Navigation } from './components/Navigation';
import { Register } from './views/auth/Register';
import { ConfirmEmail } from './views/auth/ConfirmEmail';
import { SendConfirmationEmail } from './views/auth/SendConfirmationEmail';
import { Logout } from './views/auth/Logout';

export const Back = () => {
  const navigate = useNavigate()
    
  return <h2 css={{
      position: 'absolute',
      top: '30px',
      left: '50px',
      cursor: 'pointer'
    }}
    onClick={() => {
      navigate("/home/true")
    }}
    >Back</h2>

  }
  
const FIVE_MINS = 15 * 1000 * 60;
const GENERAL_DEBOUNCE_TIME = 500;

export const IdleTimer = () => {
  const navigate = useNavigate()
  useIdleTimer({
    timeout: FIVE_MINS, // time in millisecond
    onIdle: () => {
      navigate("/")
    },
    debounce: GENERAL_DEBOUNCE_TIME, // time in millisecond
  });


  return (<></>)
}
    
export const authRouters = createMemoryRouter([
  {
    path: "/completeAccountSetup",
    element: <>
        <Logout />
        <SendConfirmationEmail />
    </>,
  },
  {
    path: "/confirm",
    element: <>
        <ConfirmEmail />
    </>,
  },
  {
    path: "/register",
    element: <>
        <Register />
    </>,
  },
  {
    path: "*",
    element: <>
        <Login />
    </>,
  },
])

export const patientRoutes = createMemoryRouter([
  {
    path: "/home/:skip",
    element:<>
    <IdleTimer />
    <Feed />
    </>
    ,

  },
  {
    path: "/update/:id",
    element: 
    <>
    <Update />
    </>,
  },
  {
    path: "/messages/:id",
    element: <>
      <NewMessages />
    </>,
  },
  {
    path: "*",
    element: <>
        <Pin />
    </>,
  },
])

export const providerRoutes = createMemoryRouter([
  {
    path: "/home/:skip",
    element: (
      <>
        <Navigation />
        <PatientList />
        <PopupMenuLogo />
      </>
    ),
  },
  {
    path: "/patient/:id",
    element: (
      <>
        <Patient/>
        <PopupMenuLogo />
      </>
    ),
  },
  {
    path: "*",
    element: <>
        <Logout/>
        <Pin />
    </>,
  },
]);