import React, { useEffect, useRef } from 'react';

interface ListProps {
  options: string[]
  optionRender?: any,
  showBorder?: boolean,
  spacing?: number,
  padding?: number,
  onClick?: (option: string) => void
  color?: string
}

export const List = ({
  options,
  showBorder = true,
  spacing = 10,
  padding = 20,
  onClick = () => {},
  color = '#d8d2c7'
}: ListProps) => {
  return (
    <div
      css={{
        padding: padding + 'px',
        paddingTop: '2px'
      }}
    >
      {options.map((option, index) => {

        return (
          <div
            css={{
              borderBottom: showBorder ? '1px solid white' : '',
              padding: spacing + 'px',
              paddingRight: 0,
              cursor: 'pointer',
              ':hover': {
                backgroundImage: 'linear-gradient(to right, rgba(255, 0, 0, 0), rgb(117 117 117 / 90%))'

              }
            }}
            onClick={() => onClick(option)}
            key={index}
          >
            <h4 css={{ color }}>{option}</h4>
          </div>
        )
      })}
    </div>
  )

}