import React, { useEffect, useState } from 'react';
import { useScreenDetector } from '../../hooks/useScreenDetector';
import { alpine_api, getAlpineAPIServer } from '../../utils/network';
import { useMutation } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import Logo from '../../assets/logo.png'
import { Button } from '../../components/Button';

export const SendConfirmationEmail = () => {
  const { isMobile } = useScreenDetector()
  const [ isConfirmationSent, setIsConfirmationSent ] = useState(false)
  const [ isError, setIsError ] = useState(false)

  const { mutate, isPending} = useMutation({
    mutationFn: async () => {

      const data = await alpine_api.post("/auth/send-verification-email")

      console.log("data: ", data)

      if (data.status === 204) {
        setIsConfirmationSent(true)
      } else {
        setIsError(true)
      }
    },
  }); 

  return (
    <div css={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: "100%",
      width: "100%",
      gap: '50px'
    }}>
      <div
        css={{
          display: 'flex',
          flexDirection: isMobile ? 'row' : 'column',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
          <div
          css={{
            minWidth: isMobile? 'unset' : '400px',
            width: isMobile ? '75%': '50%',
            border: '2px dotted white',
            padding: '50px'
          }}
        >
        
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            gap: '30px'
          }}
        >
          <img
          src={Logo}
          css={{
            alignSelf: 'center'
          }} 
          width="60"
          height="60" 
        />
            <h2>Looks like you have an incomplete setup, please confirm your email.</h2>
            <Button onClick={mutate} name='Send confirmation email'></Button>
            {isConfirmationSent && <h2>Confirmation Sent</h2>}
        
        </div>
        </div>
        </div>
    </div>
  )

}