import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form } from '../../components/Form';
import { useScreenDetector } from '../../hooks/useScreenDetector';
import { Button } from '../../components/Button';
import { AuthContext, AuthState, LogInRequest } from '../../hooks/useAuth';
import Logo from '../../assets/logo.png'

export const Login = () => {

  const navigate = useNavigate()
  const { isMobile } = useScreenDetector()
  const [ loginData, setLoginData] = useState<LogInRequest | undefined>()
  const [ signinLoading, setSigninLoading ] = useState(false)
  const [ isLoginError, setIsLoginError ] = useState(false)
  const { state, logIn, isError, error } = useContext(AuthContext)

  useEffect(() => {
    if (state === AuthState.CONFIRM_EMAIL) {
      navigate('/completeAccountSetup')
    } else {
      navigate('/')
    }
  }, [])

  const signIn = async () => {
    if (!loginData || !loginData.email || !loginData.password) {
      setIsLoginError(true)
      return
    }

    setSigninLoading(true)

    const authState = await logIn({ 
      email : loginData.email,
      password: loginData.password!
    })

    setSigninLoading(false)

    if (authState === AuthState.CONFIRM_EMAIL) {
      navigate('/completeAccountSetup')
    } else {
      navigate('/')
    }
  }

  return (
    <div css={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: "100px",
      height: "100%",
      width: "100%",
      gap: isMobile ? '40px' : '50px',
      overflow: 'hidden'
    }}>
      <div
        css={{
          display: 'flex',
          flexDirection: isMobile ? 'row' : 'row',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <div
          css={{
            minWidth: isMobile? 'unset' : '400px',
            width: isMobile ? '75%': '30%',
          }}
        >
        <img
          src={Logo}
          css={{
            alignSelf: 'center',
            marginBottom: '40px'
          }}
          width="40"
          height="40" 
        />
        <Form
          padding={0}
          textComponent={ isMobile ? 'h3' : 'h2'}
          data={{
            "email": {
              field: 'email',
              type: 'input',
              value: 'fake@email.com'
            },
            "password": {
              field: 'password',
              type: 'input',
              props: {
                type: 'password'
              },
              value: 'password1'
            }
          }}
          error={isLoginError ? "Hmm, that doesn't look right – check your username and password and give it another go" : ''}
          disable={signinLoading}
          onDataChange={(data: LogInRequest) => {
            setIsLoginError(false)
            setLoginData(data)
          }}
        />

        </div>
      </div>
        <div
          css={{
            minWidth: isMobile? 'unset' : '400px',
            width: isMobile ? '75%': '30%',
            overflow: 'hidden'
          }}
        >
          <Button
            name='Start session'
            isLoading={signinLoading}
            onClick={signIn}
            secondButtonName="Don't have an account? Join the Alpine network."
            secondButtonOnClick={() => {
              navigate('/register')
            }}
          />
        </div>

    </div>
  )

}