import React, { useEffect, useState } from 'react';
import { useScreenDetector } from '../hooks/useScreenDetector';
import { DelayedRender } from '../components/DelayedRender';
import { List } from '../components/List';
import { useTypewriter } from 'react-simple-typewriter';
import { getRooms, getUnreadMessagesCountForRoom } from '../matrix/messages';
import { useNavigate, useParams } from 'react-router-dom';
import { createNameFromMatrixId, useLogout, useUser } from '../matrix/auth';
import { getRoomsForPatient, getRoomsForSelf } from '../matrix/people';

interface FeedProps {}

const actions2 =[
  'Give an update on how the new prescription has been',
  'Respond to Dr. Melfi about managing your high blood pressure'
]

const greetins = [
  'Good Morning, Sunny. There are a few things you need to do this week.'
]

export const Feed = ({
}: FeedProps) => {
  const { isMobile, isDesktop } = useScreenDetector()
  const navigate = useNavigate()
  const [ welcomeText, setWelcomeText] = useState(greetins[Math.floor(Math.random() * greetins.length)])
  const [ currentView, setCurrentView ] = useState('For you')
  const { skip } = useParams()
  const [text, helper] = useTypewriter({words: [welcomeText], typeSpeed: skip === 'true' ? 0: 20})
  const logout = useLogout()
  const user = useUser()
  const [ messageRoom, setMessageRoom] = useState('')
  const [ updateRoom, setUpdateRoom] = useState('')
  const [ actions, setActions ] = useState(actions2)

  const shouldSkipTypewriter = skip === 'true'


  const { isDone } = helper

  const TeamItem = ({ name, chip1, chip2}: any) => {

    return (
      <div css={{ 
          paddingBottom: '10px',
        ':hover': {
          backgroundImage: 'linear-gradient(to right, rgba(255, 0, 0, 0), rgb(117 117 117 / 90%))',
        }
        }}>
        <h3 css={{marginBottom: '10px'}}>{name}</h3>
        <div css={{ display: 'flex', flexDirection: 'row', gap: '10px'}}>
        
          <div css={{ paddingLeft: '8px', paddingRight: '8px', backgroundColor: '#913333', borderRadius: '20px', lineHeight: '3px', height: '30px'}}>
            <p css={{ fontSize: '12px'}}>{chip1}</p>
          </div>
          {chip2 &&<div css={{ paddingLeft: '8px', paddingRight: '8px', backgroundColor: '#339177', borderRadius: '20px', lineHeight: '3px', height: '30px'}}>
            <p css={{ fontSize: '12px'}}>{chip2}</p>
          </div>}
        </div>
      </div>

    )

  }

  const desktopSpacing = isDesktop ? {
    width: "800px",
    paddingBottom: '100px',
    paddingTop: '100px',
  } :{
  }

  useEffect(() => {
    if (!user)  {
      return
    }

    /*
    const rooms = getRoomsForSelf(createNameFromMatrixId((user as any).user_id))
    const messageRoom = rooms.find(room => room.name.includes("messages-2"))
    const updateRoom = rooms.find(room => room.name.includes("update"))
    console.log("roomws: ", messageRoom)
    console.log("UPdateRoom : ", updateRoom)
    if (!messageRoom) {
      return 
    }

    if (updateRoom) {
      setUpdateRoom(updateRoom.roomId)
    }

    const unreadMessages = getUnreadMessagesCountForRoom(messageRoom.roomId)
    console.log("messageRoom.roomId",messageRoom.roomId )
    setMessageRoom(messageRoom.roomId)
    console.log("unreadMessages: ", unreadMessages)

    setActions([...actions, `Read ${unreadMessages ? unreadMessages : '3'} new messages from Dr. Melfi`])
    */
  }, [])

  return (
    <div 
      css={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignContent: 'center',
        height: '100%',
        overflow: 'hidden'
      }}
    >
      <div
        css={{
          padding: "30px",
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          overflow: 'hidden',
          ...desktopSpacing
        }}
      >
        {currentView === 'blank' && <div></div>}
        {currentView === 'For you' && <div>
          <h1>{shouldSkipTypewriter ? welcomeText : text}</h1>
          <DelayedRender time={1} wait={5} hide={!isDone} disabled={shouldSkipTypewriter} >
            <div 
              css={{
                marginTop: '50px',
              }}
            >
              {actions.map((action, index) => {

                return (
                  <p 
                    css={{
                      fontSize: '1.5rem',
                      cursor: 'pointer',
                    }} 
                    key={index}
                    onClick={() => {
                      switch (index) {
                        case 0:
                          navigate('/update/' + updateRoom)
                          break;
                        case 1:
                          navigate('/messages/' + messageRoom)
                          break;
                      }
                    }}
                  >
                    {action}
                  </p>
                )
              })}
            </div>

          </DelayedRender>
        </div>}
        {currentView === 'Team' && (
          <div css={{ display: 'flex', flexDirection: 'column', position: 'relative', width: "calc(100vw - 60px)", gap: '20px'}}>
            {/*<h2 css={{ position: 'absolute', right: 0, top: 0}}>New Chat</h2>*/}
            <TeamItem name="Dr. Melfi" chip1="Primary Physian" chip2="High Blood Pressure" /> 
            <TeamItem name="Dr. Johnson Smith" chip1="Psychiatrist" chip2="High Blood Pressure" /> 
            <TeamItem name="Elizabeth Casey" chip1="Acupuncturist" chip2="Stress Related Back Pain" /> 
            <TeamItem name="John Doe" chip1="Therapist" chip2="Anxiety" /> 
          </div>
        )}

        {currentView === 'Records' && (
          <div css={{ width: "100vw"}}>
            <List padding={0} showBorder={false} options={['Personal Information', 'Wearable + Mobile Data', 'Treatments', 'Diagnosis', 'Medical Tests', 'Clinical Data']} />
          </div>
        )}

        {currentView === 'Settings' && (
          <div css={{ width: "calc(100vw - 60px)"}}>
            <List
              padding={0}
              showBorder={false}
              options={['Care Team', 'Access Management', 'Manage Devices', 'Log out']}
              onClick={(option) => {
                if (option === 'Log out') {
                  logout()
                }
              }}
            />
          </div>
        )}

        <div css={{ position: 'relative'}}>
        {currentView === 'For you' &&  <>

            <svg
              css={{
                position: 'absolute',
                paddingTop: '18px',
                paddingLeft: '10px'
              }}
              height="20px"
              width="20px"
            xmlns="http://www.w3.org/2000/svg" fill='white' x="0px" y="0px" viewBox="0 0 50 50">
<path d="M 21 3 C 11.6 3 4 10.6 4 20 C 4 29.4 11.6 37 21 37 C 24.354553 37 27.47104 36.01984 30.103516 34.347656 L 42.378906 46.621094 L 46.621094 42.378906 L 34.523438 30.279297 C 36.695733 27.423994 38 23.870646 38 20 C 38 10.6 30.4 3 21 3 z M 21 7 C 28.2 7 34 12.8 34 20 C 34 27.2 28.2 33 21 33 C 13.8 33 8 27.2 8 20 C 8 12.8 13.8 7 21 7 z"></path>
</svg>
<button id="search-button"
  css={{
    position: 'absolute',
                paddingTop: '18px',
                paddingRight: '15px',
    top:0,
    right:0,
    backgroundColor: "transparent",
    border:0,
    cursor: 'pointer'
  }}

>
<svg 
css={{
  cursor: 'pointer'
}}
fill="white" height="20px" width="20px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  
	 viewBox="0 0 495.003 495.003" >
<g id="XMLID_51_">
	<path id="XMLID_53_" d="M164.711,456.687c0,2.966,1.647,5.686,4.266,7.072c2.617,1.385,5.799,1.207,8.245-0.468l55.09-37.616
		l-67.6-32.22V456.687z"/>
	<path id="XMLID_52_" d="M492.431,32.443c-1.513-1.395-3.466-2.125-5.44-2.125c-1.19,0-2.377,0.264-3.5,0.816L7.905,264.422
		c-4.861,2.389-7.937,7.353-7.904,12.783c0.033,5.423,3.161,10.353,8.057,12.689l125.342,59.724l250.62-205.99L164.455,364.414
		l156.145,74.4c1.918,0.919,4.012,1.376,6.084,1.376c1.768,0,3.519-0.322,5.186-0.977c3.637-1.438,6.527-4.318,7.97-7.956
		L494.436,41.257C495.66,38.188,494.862,34.679,492.431,32.443z"/>
</g>
</svg></button>
        <input
          css={{
            width: 'calc(100% - 50px)',
            height: 50,
            backgroundColor: 'black',
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            color: 'white',
            paddingLeft: '50px',
            border: 0,
            fontSize: '18px'
          }}
          >

          </input></>}
        <div
          css={{
            width: '100%',
            borderTop: '1px dotted white',
            display: 'flex',
            flexDirection: 'row',
            gap: isMobile ? '0' : '20px',
            justifyContent: isMobile ? 'space-between' : 'flex-start',
            overflow: 'hidden'
          }}
        >
          {['For you', 'Team', 'Records', 'Settings'].map((option, index) => {
            const selectedOption =  option === currentView ? { textDecoration : 'underline'} : {}
            return (
              <h3 
                css={{ cursor: 'pointer', ...selectedOption}}
                key={index} 
                onClick={() => {
                  setCurrentView(option)
                }}
              >
                {option}
              </h3>
            )
          })}
        </div>
      </div>
      </div>
    </div>
  )
}

