import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form } from '../../components/Form';
import { useScreenDetector } from '../../hooks/useScreenDetector';
import { Button } from '../../components/Button';
import { useAuth } from '../../hooks/useAuth';
import { LoginRequest } from 'matrix-js-sdk';
import { Pin } from './Pin';

enum Steps {
  BASIC,
  USER_TYPE,
  SET_PIN,
  CONFIRM_PIN,
}

export const Register = () => {

  const navigate = useNavigate()
  const { isMobile } = useScreenDetector()
  const [ signinLoading, setSigninLoading ] = useState(false)
  const { register, isError, error, isPending } = useAuth()

  const [ previousStep, setPreviousStep ] = useState<Steps>(Steps.BASIC)
  const [ step, setStep ] = useState<Steps>(Steps.BASIC)

  const [ loginData, setLoginData] = useState<LoginRequest | undefined>(undefined)
  const [ userInfo, setUserInfo] = useState<any | undefined>({
    role: 'Patient'
  })
  const [ pin, setPin] = useState<number | undefined>(undefined)

  const onRegister = async () => {
    if (!loginData) {
      return
    }

    setSigninLoading(true)

    delete loginData['passwordRetyped']

    await register({ 
      ...loginData,
      ...userInfo,
      pin
    })

    setSigninLoading(false)
    navigate("/")
  }


  let buttonText = ''
  if (step === Steps.BASIC) {
    buttonText = 'Next'
  } else if (step === Steps.SET_PIN) {
    buttonText = 'Set pin'
  } else if (step === Steps.USER_TYPE) {
    buttonText = 'Next'
  } else if (step === Steps.CONFIRM_PIN) {
    buttonText = 'Create account'
  }

  const onButtonClick = () => {
    setPreviousStep(step)
    switch(step) {
      case Steps.BASIC:
        setStep(Steps.USER_TYPE)
        break;
      case Steps.USER_TYPE:
        setStep(Steps.SET_PIN)
        break;
      case Steps.SET_PIN:
        setStep(Steps.CONFIRM_PIN)
        break;
      case Steps.CONFIRM_PIN:
        onRegister()
        break
    }
  }

  return (
    <div css={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: isMobile ? '': 'center',
      alignItems: 'center',
      gap: isMobile ? '35px': '50px',
      marginBottom: '50px'
    }}>
      <div
        css={{
          display: 'flex',
          flexDirection: isMobile ? 'row' : 'column',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
          <div
          css={{
            minWidth: isMobile? 'unset' : '400px',
            width: isMobile ? '85%': '50%',
            padding: isMobile?  '10px': '50px',

          }}
        >
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            gap: '30px',
            paddingBottom: isMobile ? '40px' : '',
            borderBottom: isMobile ? '2px dotted white' : '',
          }}
        >
        {step === Steps.BASIC && 
        <>
          <div>
            <h1>Welcome to Alpine!</h1>
            <p>Let's get you securely setup onto the network. First lets add your account info. </p>
          </div>
        <Form
          padding={0}
          textComponent={ isMobile ? 'h3' : 'h2'}
          data={{
            "email": {
              field: 'email',
              type: 'input',
              value: ''
            },
            "password": {
              field: 'password',
              type: 'input',
              props: {
                type: 'password'
              },
              value: ''
            },
            "passwordRetyped": {
              field: 'retype password',
              type: 'input',
              props: {
                type: 'password'
              },
              value: ''
            },
            "firstName": {
              field: 'first name',
              type: 'input',
              value: ''
            },
            "lastName": {
              field: 'last name',
              type: 'input',
              value: ''
            },
          }}
          error={isError ? "Hmm, that doesn't look right – check your username and password and give it another go" : ''}
          disable={isPending}
          onDataChange={(data: LoginRequest) => setLoginData(data)}
        />
      </>

        }
        {step === Steps.USER_TYPE && 
        <  >
          <div>
            <p>All interactions that happen on Alpine are encrypted and confidential. You can be assured that everytings good.</p>
            <p>Select how you wish to interact on the network</p>
          </div>
        <Form
          padding={0}
          textComponent={ isMobile ? 'h3' : 'h2'}
          data={{
            "role": {
              field: 'Join as a patient or provider?',
              type: 'select',
              options: ['Patient', 'Provider'],
              value: userInfo.role
            },
          }}
          error={isError ? "Hmm, that doesn't look right – check your username and password and give it another go" : ''}
          disable={isPending}
          onDataChange={(data: any) => setUserInfo(data)}
        />
        </>

        }
        {step === Steps.SET_PIN && (
          <>
          <div>
            <p>Set a pin which will be used to lock your local sessions</p>
          </div>
            <Pin
              showLogo={false}
              onComplete={(pin) => {
                setPin(pin)
              }}
            />
          </>
        )}
        {step === Steps.CONFIRM_PIN && (
          <>
            <p>Confirm your pin to create your account</p>
            <Pin showLogo={false}/>
          </>
        )}</div>
        </div>
      </div>
        <div
          css={{
            minWidth: isMobile? 'unset' : '400px',
            width: isMobile ? '85%': '30%',
            overflow: 'hidden',
          }}
        >
          <Button
            name={buttonText}
            isLoading={isPending || signinLoading}
            onClick={onButtonClick}
            secondButtonName={ step !== Steps.BASIC ? 'Previous' : ''}
            secondButtonOnClick={()=> {

              setPreviousStep(previousStep - 1)
              setStep(previousStep)

            }}
          />
        </div>

    </div>
  )

}