import React, { useEffect, useRef, useState } from 'react';
import { DelayedRender } from '../components/DelayedRender';

import {
  MainContainer,
  MessageInput,
  MessageContainer,
  MessageHeader,
  MessageList
} from "../components/react-chat-ui";
import { getRoomsMessages, listenToNewRoomMessages, sendMessageToRoom } from '../matrix/messages';
import { createNameFromMatrixId, useUser } from '../matrix/auth';

interface PatientMessagesProps {
  hideContent?: boolean
  matrixRoomId: string
}

export const PatientMessages = ({
  hideContent= false,
  matrixRoomId
}: PatientMessagesProps) => {
  const user = useUser()
  const [ messages, setMessages ] = useState<any>([])

  useEffect(() => {

    console.log("use effect called!!")

    if (!matrixRoomId) {
      return
    }

    const rawMessages = getRoomsMessages(matrixRoomId)

    const parsedMessages: any[] = []
    rawMessages.forEach((message: any) => {
      parsedMessages.push({
        text: message.message,
        user: {
          id: createNameFromMatrixId(message.sender),
          name: createNameFromMatrixId(message.sender)
        }
      })

    })

    console.log("settting!!!", rawMessages)
    console.log("settting!!!", parsedMessages)

    setMessages(parsedMessages)

    listenToNewRoomMessages(matrixRoomId, (message: any) => {
      setMessages((currentMessages: any) => {
        return [...currentMessages, {
          text: message.message,
          user: {
            id: createNameFromMatrixId(message.sender),
            name: createNameFromMatrixId(message.sender)
          }
        }]
      })
    })

  }, [ matrixRoomId ])

  return (
    <DelayedRender hide={hideContent} time={hideContent ? 0.2 : 1.2}>
      <div css={{ padding: "10px", paddingBottom: '0', width: '100%', margin: '20px', height: 'calc(100% - 100px)' }}>
        <MessageContainer
        >
          <MessageList
            currentUserId={user ? createNameFromMatrixId((user as any).user_id): ''}
            messages={messages}
          />
          <MessageInput placeholder="Type message here" onSendMessage={(text) => {
            sendMessageToRoom(matrixRoomId, text)
          }} />
        </MessageContainer>
      </div></DelayedRender>
  )

}