import { useMutation } from "@tanstack/react-query";

import { queryClient } from "../App";
import useLocalStorage from "use-local-storage";
import { getClient } from "./start";
import { useNavigate } from "react-router-dom";
const baseUrl = "http://localhost:8008"

export const LOCAL_LOGIN_INFO = 'MATRIX-LOGIN'

const getLoginURLS = async () => {
  const ret= await fetch("https://matrix.org/.well-known/matrix/client")
  const res = await ret.json()
  return res
}

export const createMatrixIdFromName = async (name: string) => `${name}@matrix.org`
export const createNameFromMatrixId = (name: string) => {
  const split = name.split(":")
  return split[0].substring(1, split[0].length)
}

export const login = async (
  user: string,
  password: string
) => {
  const ret = await fetch(baseUrl + "/_matrix/client/v3/login",{
    method: 'POST',
    body: JSON.stringify({ 
      identifier: {type: "m.id.user", user },
      initial_device_display_name: "app.element.io: Chrome on macOS",
      password,
      type: "m.login.password"
    })

  })
  const res = await ret.json()
  return res
}

export const useLoginUser = () => {
  const [ _, setLocal ] = useLocalStorage(LOCAL_LOGIN_INFO, undefined)
  const { data, isError, error, mutate, mutateAsync, isPending} = useMutation({
    mutationKey: ['loginData'],
    mutationFn: async ({username, password}: any) => {
      const loginUrls = getLoginURLS()
      const loginData = await login(username, password)
      console.log("loginData: ", loginData)

      if (loginData.errcode === 'M_FORBIDDEN') {
        throw new Error("Forbidden")
      }

      queryClient.setQueryData(['loginData'], loginData)
      setLocal(loginData)
      return loginData
    },
  });

  return { data, isError, error, mutate, isPending };
};

export const useLogout = () => {
  const [ _, setLocal ] = useLocalStorage(LOCAL_LOGIN_INFO, undefined)
  const navigate = useNavigate()

  const logout = async () => {
    setLocal(undefined)
    await getClient().logout()
    navigate('/auth')
  }

  return logout
}

export const useUser = () => {
  const [ user, setLocal ] = useLocalStorage(LOCAL_LOGIN_INFO, undefined)
  return user 
}