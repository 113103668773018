import React, { useEffect, useRef } from 'react';
import Logo from '../../assets/logo.png'
import mountains from '../../assets/mountains.png'
import PinField from "react-pin-field"
import { useNavigate } from 'react-router-dom';
import { useScreenDetector } from '../../hooks/useScreenDetector';

export const Pin = ({
  text,
  showLogo = true,
  onComplete 
}: {
  text?: string,
  showLogo?: boolean,
  onComplete?: (code: any) => void
}) => {
  const pinRef = useRef()
  const navigate = useNavigate()

  const { isMobile } = useScreenDetector()

  useEffect(() => {
    if (!pinRef.current) {
      return 
    }

    if ((navigator as any).virtualKeyboard) {
      (navigator as any).virtualKeyboard.show();
    }


  }, [pinRef.current]);

  return (
    <div css={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: "100%",
      width: "100%",
      gap: '120px'
    }}>
      {isMobile && showLogo &&
        <img
          src={Logo}
          css={{
            alignSelf: 'center'
          }} 
          width="60"
          height="60" 
        />}
      <div css={{ marginLeft: '10px', display: 'flex', flexDirection: "column", justifyContent: 'center'}}>
      <div css={{ display: 'flex', flexDirection:"row"}}>
      <PinField
        style={{
          height: isMobile ? "50px": "60px",
          width: isMobile ? "30px" : "40px",
          borderBottom: "1px white solid",
          backgroundColor: "#141414",
          color: "white",
          fontSize: "25px",
          marginRight: '20px',
          textAlign: 'center',
        }}
        length={4}
        validate={"1234567890"}
        onComplete={(code) => {
          if (onComplete) {
            onComplete(code)
          } else {
            navigate("/home/1")
          }
        }}
        inputMode="numeric"
        ref={pinRef as any}
        type='password'
        pattern="[0-9]"
      />

      </div>
      
      {text && <p style={{ marginTop: '40px', textAlign: 'center'}}>{text}</p>}
      </div>

      {!isMobile && showLogo && 
        <img
          src={Logo}
          css={{
            alignSelf: 'center'
          }} 
          width="50"
          height="50" 
        />}
    </div>
  )

}