import React, { useEffect, useRef, useState } from 'react';

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Upload } from '../../components/Upload';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { sendMessageToRoom, testSendToRoom } from '../../matrix/messages';
import { useScreenDetector } from '../../hooks/useScreenDetector';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../components/Button';



interface UpdateProps {
}

export const Update = ({
}: UpdateProps) => {
  const [editorContent, setEditorContent] = useState('')
  const { isMobile } = useScreenDetector()
  const navigate = useNavigate()
  const { id }= useParams()

  const onSend = async () => {
    if (!id){
      return 
    }

    await sendMessageToRoom(id, editorContent)
    navigate("/home/true")
  }

  return (
    <div
      css={{
        display:'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100vw',
        height: '100vh',
        gap: '30px',
        overflow: 'hidden' 
      }}
    >
        <h2 css={{
          width: '100%',
          marginLeft: '50px'
    }}
    onClick={() => {
      navigate("/home/true")
    }}
    >Back</h2>
    <textarea placeholder='Add your thoughts here...' 
      css={{
        width: '100%',
        height: '40%',
        padding: '30px',
        boxSizing: 'border-box',
        fontSize: '1rem'

      }}
      onChange={(e) => setEditorContent(e.currentTarget.value)}
    />
      <Button name='Send' onClick={onSend}/>
    </div>
  )

}