import React, { useEffect, useRef, useState } from 'react';

interface DelayedRenderProps {
  time?: number;
  hide?: boolean;
  wait?: number;
  disabled?: boolean;
  children: React.ReactNode
}

export const DelayedRender = ({
  time = 1,
  hide,
  wait,
  children,
  disabled = false
}: DelayedRenderProps) => {

  const divRef = useRef<HTMLDivElement>(null)
  const [ currentOpacity, setCurrentOpacity] = useState(disabled ? "1": "0")

  useEffect(() => {

    if (!divRef.current || disabled) {
      return
    }

    if (!hide) {
      return
    }

    setTimeout(() => {
    if (!divRef.current) {
      return
    }
      divRef.current.style.opacity = "1"
      setCurrentOpacity("1")
    }, wait ? wait * 1000: 0)

  }, [ divRef.current])

  useEffect(() => {
    if (!divRef.current || disabled) {
      return
    }

    if (hide === null) {
      return
    }

    if (hide){
      divRef.current.style.opacity = "0"
      
    } else {
      divRef.current.style.opacity = "1"
    }

  }, [hide])

  return (
    <div
      ref={divRef}
      css={{
        opacity:currentOpacity,
        height: '100%',
        width: '100%',
        transition: 'opacity '+time+'s ease-in'
      }}
    >
        {children}
    </div>
  )

}