import React, { useEffect, useRef, useState } from 'react';

interface TabsProps {
  startingTab?: number
  options: string[]
  optionRender: { [key: string]: React.ReactNode }
  disable: boolean
}

export const Tabs = ({
  startingTab = 0,
  options,
  optionRender,
  disable
}: TabsProps) => {
  const [selectedTab, setSelectedTab] = useState(options[startingTab])
  return (
    <>
      <div
        css={{
          borderBottom: "2px white dotted",
          display: 'flex',
          flexDirection: 'row',
          gap: "30px",
          paddingLeft: "20px",
        }}
      >
        {options.map((option, index) => {

          const underlined = option === selectedTab && !disable  ? { textDecoration: "underline"} : {}
          
          return (
          <h2
            key={index}
            css={{ cursor: 'pointer', ...underlined }}
            onClick={() => setSelectedTab(option)}
          >
            {option}
          </h2>
        )})}
      </div>

      {optionRender[selectedTab]}
    </>
  )

}