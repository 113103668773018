import React, {  useRef, useState } from 'react'
import styled from 'styled-components'
import useTypingListener from '../hooks/useTypingListener'

export type Props = {
    onSendMessage?: (text: string) => void
    themeColor?: string
    mobileView?: boolean
    onStartTyping?: () => void
    onEndTyping?: () => void
    // showAttachButton?: boolean
    onAttachClick?: () => void
    placeholder?: string

    onKeyDown?: React.KeyboardEventHandler<HTMLInputElement> | undefined
    onKeyUp?: React.KeyboardEventHandler<HTMLInputElement> | undefined


}

const Container = styled.div<{ mobile?: boolean }>`
box-sizing: border-box;
position: relative;
width: 100%;
display: flex;


${({ mobile }) => mobile ? `
    padding-right: 0px;

`:
        ` 
padding-right: 12px;
 `}
`
const Form = styled.form`
background-color:rgb(20 20 20);
padding-top: 8px;
padding-bottom: 8px;
border-bottom-right-radius: 16px;
border-bottom-left-radius: 16px;
box-shadow:0px -1px 0px rgba(0, 0, 0, 0.07999999821186066);
position: relative;
width: 100%;
display: flex;
align-items: end;
box-sizing: border-box;
`

const InputContainer = styled.div`
width: 100%;
display: flex;
align-items: center;
justify-content: center;
position: relative;
box-sizing: border-box;
`

const InputBackground = styled.div<{ bgColor?: string }>`
height: 100%;
width: 100%;
border-radius:0.7rem;
position: absolute;
background-color: white;
border:1px solid #ecebeb;

`


const InputElementContainer = styled.div`
        padding: 8px;
    padding-left: 16px;
    padding-right: 16px;
    width:100%;
`

const InputElement = styled.div`
    width:100%;
    border: none;
    max-height: 6.4em;
    /* Adjust this value to control the maximum number of lines */
    position:relative;
    font-size:14px;
    overflow: scroll;

    color: rgba(0,0,0,.87);
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    background-color: transparent;
    text-align:left;
    opacity: 1;
  
    min-height: 1.6em;
    line-height:1.6em;
    word-wrap: break-word;
    overflow-wrap: anywhere;
  
    &:focus{
        outline: none;
    }

    ::-webkit-scrollbar {
    display: none;
    }

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
 `


const ArrowContainer = styled.div<{ showCursor: boolean }>`
    position: relative;
    padding-left:16px;
    padding-right:16px;
    cursor: ${({ showCursor }) => showCursor ? 'pointer' : 'default'};
    display: flex;
    align-items: end;
    opacity: ${({ showCursor }) => showCursor ? '1' : '0.4'};
    height: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
    &:hover {
        opacity: 1 
    }


`

const AttachmentContainer = styled.div`
    position: relative;
    padding-left:16px;
    padding-right:16px;
    cursor: pointer;
    display: flex;
    align-items: end;
    opacity:1;
    height: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
    

`

const PlaceHolder = styled.span`
    color: #9ca3af;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    padding-left: 16px;
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size:12px;
    pointer-events: none;


`

export default function MessageInput({
    onSendMessage,
    themeColor = '#6ea9d7',
    mobileView,
    onStartTyping,
    onEndTyping,
    // showAttachButton = true,
    onAttachClick,
    placeholder = 'Send a message...',
    onKeyDown,
    onKeyUp
}: Props) {

    const [text, setText] = useState("")
    const inputRef = useRef<any>(null);

    const { setTyping, ...inputProps } = useTypingListener({ onStartTyping, onEndTyping })

    const handleSubmit = () => {
        if (text.trim().length > 0) {
            inputRef.current.innerText = ''
            setTyping(false)
            onSendMessage && onSendMessage(text.trim())
            setText("")

        }
    }

    return (
        <Container
            mobile={mobileView}
        >

            <Form
                data-testid='message-form'
                className='fade-animation'
                onSubmit={(e: any) => {
                    e.preventDefault()
                    handleSubmit()
                }}
            >

                {/* {showAttachButton && ( */}
        

                {/* )} */}

                <InputContainer
                >
                    <InputBackground
                        bgColor={themeColor}
                    />


                    <InputElementContainer>
                        <InputElement
                            ref={inputRef}
                            data-testid='message-input'
                            onInput={(event: any) => setText(event.target.innerText)}
                            contentEditable="true"
                            suppressContentEditableWarning={true}
                            onKeyDown={(event: any) => {
                                if (event.key === 'Enter') {
                                    event.preventDefault();  // Prevents adding a new line
                                    handleSubmit();
                                    return;
                                }

                                inputProps.onKeyDown()
                                onKeyDown && onKeyDown(event)
                            }}
                            onKeyUp={(event: any) => {
                                inputProps.onKeyUp()
                                onKeyUp && onKeyUp(event)
                            }}
                        />
                        {text === '' && <PlaceHolder>{placeholder}</PlaceHolder>}
                    </InputElementContainer>
                </InputContainer>



                <ArrowContainer
                    showCursor={text.trim().length > 0}
                    onClick={handleSubmit}
                >

                    <svg
                        fill={'white'}
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 512 512" >
                        <path d="M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L284 427.7l-68.5 74.1c-8.9 9.7-22.9 12.9-35.2 8.1S160 493.2 160 480V396.4c0-4 1.5-7.8 4.2-10.7L331.8 202.8c5.8-6.3 5.6-16-.4-22s-15.7-6.4-22-.7L106 360.8 17.7 316.6C7.1 311.3 .3 300.7 0 288.9s5.9-22.8 16.1-28.7l448-256c10.7-6.1 23.9-5.5 34 1.4z" />
                    </svg>

                </ArrowContainer>
            </Form >
        </Container>

    )
}